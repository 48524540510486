import axios from '@/api/config/interceptor'
import { host } from '@/api/config/host'
import { _paramsToQueryString } from '@/utils/utils'

//注册
export const register = (params) => {
    return new Promise((resolve, reject) => {
        axios.post(`${host}/api/Auth/UserRegister`, params).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//获取注册角色列表
export const getRegisterRole = () => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Auth/GetIdentitys`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//发送验证码
export const sendCode = (params) => {
    return new Promise((resolve, reject) => {
        axios.get(`${host}/api/Auth/SendAuthCode?${_paramsToQueryString(params)}`).then(res => {
            if (res.code == 0) {
                resolve(res.data)
            } else {
                reject(res)
            }
        })
    })
}

//发送验证码
export const getAuthBank = (params) => {
  return new Promise((resolve, reject) => {
      axios.get(`${host}/api/Wallet/AuthBankCard?${_paramsToQueryString(params)}`).then(res => {
          if (res.code == 0) {
              resolve(res.data)
          } else {
              reject(res)
          }
      })
  })
}